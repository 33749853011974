exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-index-js": () => import("./../../../src/pages/sitemap/index.js" /* webpackChunkName: "component---src-pages-sitemap-index-js" */),
  "component---src-pages-unicom-global-divisions-index-js": () => import("./../../../src/pages/unicom-global/divisions/index.js" /* webpackChunkName: "component---src-pages-unicom-global-divisions-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-client-search-template-js": () => import("./../../../src/templates/ClientSearchTemplate.js" /* webpackChunkName: "component---src-templates-client-search-template-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-page-items-js": () => import("./../../../src/templates/news-page-items.js" /* webpackChunkName: "component---src-templates-news-page-items-js" */),
  "component---src-templates-real-estate-js": () => import("./../../../src/templates/real-estate.js" /* webpackChunkName: "component---src-templates-real-estate-js" */),
  "component---src-templates-root-js": () => import("./../../../src/templates/root.js" /* webpackChunkName: "component---src-templates-root-js" */)
}

